import React from 'react';
import OpticalHeading from 'ui/elements/Heading/OpticalHeading';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import _get from 'lodash/get';

import * as S from './styles';

const Sidebar = (props) => {
  const { t } = useTranslation('faculty');
  const assetId = _get(props, 'image.asset_id');
  const hotspot = _get(props, 'image.hotspot');
  const crop = _get(props, 'image.crop');

  return (
    <React.Fragment>
      <S.FacultyImage
        assetId={assetId}
        hotspot={hotspot}
        crop={crop}
        fluidOptions={{ maxWidth: 770 }}
      />

      <OpticalHeading level={6} renderAs="h2" color='primary'>
        {t('expertise')}
      </OpticalHeading>

      <S.FacultyList>
        {
          props.expertise.map((expertise, i) => (
            <li key={i}>{expertise}</li>
          ))
        }
      </S.FacultyList>
    </React.Fragment>
  )
};

export default Sidebar;
