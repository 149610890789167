import styled from 'styled-components';

import Image from 'widgets/Image';

export const FacultyImage = styled(Image)`
  margin-bottom: 30px;
`

export const FacultyList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  color: ${(props) => props.theme.palette.primary};
  font-size: 1.4rem;
  
  li {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid currentColor;
    
    &:last-child {
      border-bottom: 0;
    }
  }
`;
