import React, { useMemo } from 'react'
import { graphql } from 'gatsby';
import _get from 'lodash/get';
import OpticalHeading from 'ui/elements/Heading/OpticalHeading';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import ContentBox from 'widgets/ContentBox';
import withLocalization from 'decorators/withLocalization';
import { Section } from 'components/Layout/styles';
import PageLayout from 'components/Layout/PageLayout';
import { flattenResponse, buildPersonaData } from 'utils/helpers';

import Sidebar from 'modules/Faculty/Sidebar';
import Programs from 'modules/Faculty/Programs'

const Faculty = ({
  data: rawData,
  location,
}) => {
  const { t, i18n } = useTranslation('faculty');
  const data = _get(rawData, 'sanityFaculty');
  const programs = flattenResponse(rawData, 'allSanityProgram');
  const { fullName } = buildPersonaData(data);

  // single faculty uses hero's image from faculty index
  const heroProps = {
    type: 'polygon',
    data: _get(rawData, 'sanityFacultyIndex'),
  }

  const title = useMemo(() => {
    return data?.meta?.title?.[i18n.language] ?? fullName
  }, [data, fullName, i18n])

  return (
    <PageLayout data={data} heroProps={heroProps} sidebar={Sidebar} title={fullName}>
      <GatsbySeo
        title={title}
        openGraph={{
          title: fullName,
          url: location.href,
          locale: i18n.language,
        }}
      />
      <Section>
        <p>
          <strong>
            {`${_get(data, 'position')} at ${_get(data, 'organization')}`}
          </strong>
        </p>
        <ContentBox blocks={data._rawBio} />
      </Section>

      {
        !!programs.length && (
          <Section>
            <OpticalHeading level={6}>
              {
                t('currentlyTeaches', { faculty: fullName })
              }
            </OpticalHeading>
            <Programs data={programs} />
          </Section>
        )
      }
    </PageLayout>
  );
};

export const query = graphql`
  query Faculty($id: String, $language: String!) {
    sanityFacultyIndex {
      hero {
        ...HeroFragment
      }
    }
    sanityFaculty(id: { eq: $id }) {
      ...PersonaFragment
      meta {
        ...MetaFragment
      }
    }
    allSanityProgram(
      filter: {
        faculty: {
          elemMatch: {
            id: { eq: $id } }
        }
      }
    ) {
      edges {
        node {
          id
          title
        }
      }
    }

    locales: allLocale(filter: { language: {eq: $language} }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default withLocalization(Faculty);
